//配置全局访问接口地址
//let commonUrl = "http://back.lc.local/";

let commonUrl = "https://api.brajil.com/";


let baseUrl = {
    commonUrl
}

export default baseUrl